<template>
  <div class="ApprovalTag">
    <!-- 我的任务 -->
    <van-nav-bar
      :title="'我的任务'"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content">
      <van-search
        v-model="searchVal"
        shape="round"
        :placeholder="'搜索'"
        @search="onSearch"
      />
      <van-tabs
        v-model="activeName"
        color="#2B8DF0"
        title-active-color="#2B8DF0"
        @change="changeAct"
      >
        <van-tab :title="'我的任务'" name="s0"></van-tab>
        <!-- <van-tab :title="'我接收的'" name="s1"></van-tab> -->
        <van-tab :title="'我下达的'" name="s2"></van-tab>
      </van-tabs>

      <van-pull-refresh v-model="isLoadingAwait" @refresh="onRefreshAwait">
        <van-list
          v-model="loadingAwait"
          :finished="finishedAwait"
          :finished-text="$t('module.noMore')"
          @load="onLoadAwait"
        >
          <div
            class="zb_group"
            v-for="(item, index) in assessList"
            :key="index"
          >
            <van-cell
              class="ass_cell"
              size="large"
              :title="item.taskname"
              :label="`${item.empname}-${item.deptname}`"
              @click="goToApply(item)"
            >
             <template slot="label">
                  {{ item.empname }}-{{ item.deptname }}<br />
                  周期：{{ item.khtimename }}
                </template>
              <template>
                <div style="color:red">
                  权重：{{ item.taskper }}
                  <template v-if="item.xjdf"> 总分：{{ item.xjdf }} </template>
                </div>
                <div>{{ item.reqdate }}</div>
              </template>
            </van-cell>
            <div class="ass_right">
              <div
                class="status_txt"
                :class="
                  item.flagclass == 1
                    ? 'agree'
                    : item.flagclass == 2
                    ? 'un_agree'
                    : item.flagclass == 3
                    ? 'toBe_submit'
                    : 'assing'
                "
              >
                <span>{{ item.flag }}</span>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {
  getMyReceiveGsTaskList,
  getMyReqGsTaskList,
  getMySendGsTaskList
} from '@api/wxjx.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      activeName: 's0',
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      sureList: [],
      checkList: [],
      searchVal: '',
      sureNum: '',
      checkNum: ''
    }
  },
  watch: {
    searchVal(n) {
      this.onSearch()
    }
  },
  created() {},
  methods: {
    onSearch() {
      if (this.searchVal) {
        this.assessList = this.assessList.filter(
          item =>
            item.empname.indexOf(this.searchVal) > -1 ||
            item.deptname.indexOf(this.searchVal) > -1 ||
            item.taskname.indexOf(this.searchVal) > -1
        )
      } else {
        this.getList()
      }
    },
    onClickLeft() {
      this.$router.push('/home')
    },
    // 9.1.获取待审批的360考核方案列表
    getList() {
      let _this = this
      if (_this.activeName == 's0') {
        getMyReqGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = _this.getflag(res.data)
            _this.loadingAwait = false
            _this.finishedAwait = true
            _this.getflag()
          }
        })
      } else if (_this.activeName == 's1') {
        getMyReceiveGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = _this.getflag(res.data)
            _this.loadingAwait = false
            _this.finishedAwait = true
            _this.getflag()
          }
        })
      } else if (_this.activeName == 's2') {
        getMySendGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = _this.getflag(res.data)
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
        })
      }
    },
    getflag(array) {
      array.forEach(e => {
        e.flag = ''
        if (e.tasksource == '上级通知') {
          switch (e.sure_flag) {
            case 0:
              e.flag = '待确认'
              break
            case 1:
              e.flag = '员工同意'
              break
            case 2:
              e.flag = '不同意'
              break
            case 3:
              e.flag = '上级作废任务'
              break
            default:
              break
          }
        } else if (e.tasksource == '员工提交') {
          switch (e.check_flag) {
            case 0:
              e.flag = '待审批'
              break
            case 1:
              e.flag = '审批通过'
              break
            case 2:
              e.flag = '不通过'
              break
            case 3:
              e.flag = '员工作废任务'
              break
            default:
              break
          }
        }
        if (e.flag == '员工同意' || e.flag == '审批通过') {
          // 判断提价状态
          switch (e.post_flag) {
            case 0:
              e.flag = '待提交'
              break
            case 1:
              e.flag = '已提交'
              break
            case 2:
              e.flag = '已评分'
              break
            case 3:
              e.flag = '已撤回'
              break
            default:
              break
          }
        } else if (e.flag == '上级作废任务' || e.flag == '员工作废任务') {
          // 判断作废状态
          switch (e.list_flag) {
            case 0:
              e.flag = '未作废'
              break
            case 1:
              e.flag = '作废中'
              break
            case 2:
              e.flag = '已作废'
              break
            default:
              break
          }
        }
        // class
        e.flagclass = 0
        let c1 = ['已提交', '已评分'],
          c2 = ['不同意', '不通过', '已作废'],
          c3 = ['待审批', '待确认', '待提交', '未作废'],
          c4 = ['已撤回', '作废中']
        if (c1.indexOf(e.flag) > -1) {
          e.flagclass = 1
        } else if (c2.indexOf(e.flag) > -1) {
          e.flagclass = 2
        } else if (c3.indexOf(e.flag) > -1) {
          e.flagclass = 3
        } else if (c4.indexOf(e.flag) > -1) {
          e.flagclass = 4
        }
      })
      return array
    },
    onLoadAwait() {
      // 异步更新数据
      if (this.isLoadingAwait) {
        this.assessList = []
        this.isLoadingAwait = false
      }
      this.getList()
    },
    onRefreshAwait() {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      // Toast('刷新成功')
    },
    // tab切换事件
    changeAct(name, title) {
      this.onRefreshAwait()
    },
    //
    goToApply(item) {
      let isSure = true
      if (this.activeName == 'Sure') {
        isSure = true
      } else {
        isSure = false
      }
      this.$router.push({
        path: '/queTaskApply',
        query: {
          title: item.taskname,
          isSure: isSure,
          autoid: item.autoid
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ApprovalTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
    }
    .KPITagRefresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 220px);
    }

    .zb_group {
      display: flex;
      margin: 30px 0;
      .ass_cell {
        margin: 10px 0;
        .van-cell__title {
          flex: 2;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 54px;
          color: #333333;
        }
      }
      .ass_right {
        background-color: #fff;
        .status_txt {
          align-items: center;
          display: flex;
          justify-content: center;
          height: 164px;
          width: 100px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          // background-image: url("~@/assets/img/360ty.png");
          span {
            width: 1em;
          }
        }
        .agree {
          background: url('~@/assets/img/360ty.png') no-repeat;
          background-size: 100% 100%;
        }
        .un_agree {
          background: url('~@/assets/img/360bty.png') no-repeat;
          background-size: 100% 100%;
        }
        .toBe_submit {
          background: url('~@/assets/img/360dtj.png') no-repeat;
          background-size: 100% 100%;
        }
        .assing {
          background: url('~@/assets/img/360sh.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
